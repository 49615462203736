@mixin combined($path) {
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('#{$path}/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('#{$path}/open-sans-v18-latin-regular.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('#{$path}/open-sans-v18-latin-regular.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('#{$path}/open-sans-v18-latin-regular.woff') format('woff'),
      /* Modern Browsers */ url('#{$path}/open-sans-v18-latin-regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('#{$path}/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('#{$path}/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('#{$path}/open-sans-v18-latin-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('#{$path}/open-sans-v18-latin-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */ url('#{$path}/open-sans-v18-latin-600.woff')
        format('woff'),
      /* Modern Browsers */ url('#{$path}/open-sans-v18-latin-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('#{$path}/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('#{$path}/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('#{$path}/open-sans-v18-latin-700.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('#{$path}/open-sans-v18-latin-700.woff2')
        format('woff2'),
      /* Super Modern Browsers */ url('#{$path}/open-sans-v18-latin-700.woff')
        format('woff'),
      /* Modern Browsers */ url('#{$path}/open-sans-v18-latin-700.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('#{$path}/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
}

@mixin separate($path) {
  @error ("OpenSans does not have separate implemented");
}
