@mixin set-vars() {
  --primary-800: #0168b4;
  --primary-700: #0071bb;
  --primary-600: #0675cd;
  --primary-500: #038ad3;
  --primary-400: #b3dff1;
  --primary-300: #e5f3fb;

  --secondary-800: #4c346f;
  --secondary-700: #583c80;
  --secondary-600: #6f4ca2;
  --secondary-500: #8a6ab9;
  --secondary-400: #a58cc9;
  --secondary-300: #dcd2ea;

  --success-800: #204814;
  --success-700: #32701e;
  --success-600: #3b8524;
  --success-500: #55c134;
  --success-400: #84d76a;
  --success-300: #b6e7a7;

  --warning-800: #b87900;
  --warning-700: #eb9a00;
  --warning-600: #ffc453;
  --warning-500: #ffd585;
  --warning-400: #ffde9e;
  --warning-300: #ffe7b8;

  --error-800: #872221;
  --error-700: #9c2726;
  --error-600: #af2c2b;
  --error-500: #d55453;
  --error-400: #e9a5a5;
  --error-300: #f3cece;

  --tone-900: #0d0d0d;
  --tone-800: #262626;
  --tone-700: #414141;
  --tone-600: #676767;
  --tone-500: #757575;
  --tone-400: #d9d9d9;
  --tone-300: #ebebeb;
  --tone-200: #f6f6f6;
  --tone-100: #ffffff;
}
