@use '../abstracts/typography-presets/dg-new-styles' as dgns-typography-preset;
@use '../abstracts/variables/all' as v;
@use '../abstracts/spacing' as s;
@use '../abstracts/color-palettes/dg-new' as palette-preset;

@mixin dg-new() {
  @include v.defaults();
  @include palette-preset.set-vars();

  --primary-interactive-color: var(--primary-500);
  --primary-interactive-color--hover: var(--primary-700);
  --primary-interactive-color--active: var(--primary-700);

  --heading--highlight-color: var(--heading-color);

  // Default theme
  --footer: var(--tone-300);
  --footer--contrast: var(--tone-700);

  //buy now button - not part of theme
  --btn-secondary-copy: var(--primary-300);

  //note not part of theme
  --basket-icon: var(--accent);

  --max-input-width: 348px;
  --landscape-column-width: 440px;

  // Buttons
  --btn-height: auto;
  --btn-width: auto;
  --btn-max-width: 100%;
  --btn-max-width-sm: 100%;
  --btn-justify-content: space-between;
  --btn-text-align: left;
  --btn-padding: 16px 30px;
  --btn-icon-spacing: 30px;

  --btn-border-width: 2px;
  --btn-disabled-shadow: none;
  --btn-disabled-opacity: 0.5;
  --btn-hover-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  --btn-active-scale: 0.95;

  --btn-primary-color: var(--primary-interactive-contrast-color);
  --btn-primary-background-color: var(--primary-interactive-color);
  --btn-primary-border-color: var(--primary-interactive-color);
  --btn-primary-hover-color: var(--primary-interactive-contrast-color--hover);
  --btn-primary-hover-background-color: var(--primary-interactive-color--hover);
  --btn-primary-hover-border-color: var(--primary-interactive-color--hover);

  --btn-primary-inverted-color: var(--primary-800);
  --btn-primary-inverted-background-color: var(--primary-300);
  --btn-primary-inverted-border-color: var(--primary-300);
  --btn-primary-inverted-hover-color: var(--primary-800);
  --btn-primary-inverted-hover-background-color: var(--primary-400);
  --btn-primary-inverted-hover-border-color: var(--primary-400);
  --btn-primary-inverted-active-color: var(--primary-800);
  --btn-primary-inverted-active-background-color: var(--primary-400);
  --btn-primary-inverted-active-border-color: var(--primary-400);
  --btn-primary-inverted-focus-border-color: #8ccaed; //todo: where does this come from?

  --btn-secondary-color: var(--tone-700);
  --btn-secondary-background-color: var(--primary-interactive-contrast-color);
  --btn-secondary-border-color: var(--primary-interactive-color);
  --btn-secondary-hover-color: var(--primary-interactive-contrast-color--hover);
  --btn-secondary-hover-background-color: var(
    --primary-interactive-color--hover
  );
  --btn-secondary-hover-border-color: var(--primary-interactive-color--hover);

  --btn-secondary-active-color: var(
    --primary-interactive-contrast-color--active
  );
  --btn-secondary-active-background-color: var(--primary-interactive-color);
  --btn-secondary-active-border-color: var(--primary-interactive-color);

  --btn-primary-disabled-background-color: var(--btn-primary-background-color);
  --btn-secondary-disabled-background-color: var(
    --btn-secondary-background-color
  );
  --btn-tertiary-disabled-background-color: var(
    --btn-tertiary-background-color
  );
  --btn-primary-disabled-border-color: var(--btn-primary-border-color);
  --btn-secondary-disabled-border-color: var(--btn-secondary-border-color);
  --btn-tertiary-disabled-border-color: var(--btn-tertiary-border-color);
  --btn-primary-disabled-color: var(--btn-primary-color);
  --btn-secondary-disabled-color: var(--btn-secondary-color);
  --btn-tertiary-disabled-color: var(--btn-tertiary-color);
  --btn-primary-disabled-hover-background-color: var(
    --btn-primary-hover-background-color
  );
  --btn-secondary-disabled-hover-background-color: var(
    --btn-secondary-hover-background-color
  );
  --btn-tertiary-disabled-hover-background-color: var(
    --btn-tertiary-hover-background-color
  );
  --btn-primary-disabled-hover-border-color: var(
    --btn-primary-hover-border-color
  );
  --btn-secondary-disabled-hover-border-color: var(
    --btn-secondary-hover-border-color
  );
  --btn-tertiary-disabled-hover-border-color: var(
    --btn-tertiary-hover-border-color
  );
  --btn-primary-disabled-hover-color: var(--btn-primary-hover-color);
  --btn-secondary-disabled-hover-color: var(--btn-secondary-hover-color);
  --btn-tertiary-disabled-hover-color: var(--btn-tertiary-hover-color);

  --btn-tertiary-color: var(--primary-interactive-color);
  --btn-tertiary-background-color: var(--primary-interactive-contrast-color);
  --btn-tertiary-border-color: var(--primary-interactive-contrast-color);

  --btn-tertiary-hover-color: var(--primary-interactive-contrast-color--hover);
  --btn-tertiary-hover-background-color: var(
    --primary-interactive-color--hover
  );
  --btn-tertiary-hover-border-color: var(--primary-interactive-color--hover);
  --btn-tertiary-active-color: var(
    --primary-interactive-contrast-color--active
  );
  --btn-tertiary-active-background-color: var(
    --primary-interactive-color--active
  );
  --btn-tertiary-active-border-color: var(--primary-interactive-color--active);
  --btn-tertiary-text-decoration: none;
  --btn-tertiary-hover-text-decoration: none;
  --btn-tertiary-active-text-decoration: none;

  //toggle buttons (Yes/No)
  --btn-toggle-color: var(--tone-700);
  --btn-primary-text-decoration: none;
  --btn-toggle-background-color: var(--secondary-interactive-contrast-color);
  --btn-toggle-border-color: var(--secondary-interactive-color);
  --btn-toggle-hover-color: var(--secondary-interactive-contrast-color);
  --btn-toggle-hover-text-decoration: none;
  --btn-toggle-hover-background-color: var(
    --secondary-interactive-color--hover
  );
  --btn-toggle-hover-border-color: var(--secondary-interactive-color--hover);
  --btn-toggle-active-color: var(--secondary-interactive-contrast-color);
  --btn-toggle-active-text-decoration: none;
  --btn-toggle-active-background-color: var(--secondary-interactive-color);
  --btn-toggle-active-border-color: var(--secondary-interactive-color);
  --btn-toggle-focus-border-color: var(--btn-toggle-hover-border-color);
  --btn-toggle-disabled-color: var(--btn-toggle-color);
  --btn-toggle-disabled-text-decoration: var(--btn-toggle-text-decoration);
  --btn-toggle-disabled-background-color: var(--btn-toggle-background-color);
  --btn-toggle-disabled-border-color: var(--btn-toggle-border-color);
  --btn-toggle-disabled-hover-color: var(--btn-toggle-hover-color);
  --btn-toggle-disabled-hover-text-decoration: var(
    --btn-toggle-hover-text-decoration
  );
  --btn-toggle-disabled-hover-background-color: var(
    --btn-toggle-hover-background-color
  );
  --btn-toggle-disabled-hover-border-color: var(
    --btn-toggle-hover-border-color
  );

  // Borders
  --border-color--interactive: var(--tone-600);
  //disable green border on valid fields
  --input-border-color--valid: var(--input-border-color);
  --input-border-color--focus: var(--input-border-color);
  --border-radius-sm: 2px;
  --border-radius-md: 2px;
  --border-radius-lg: 4px;

  // Forms
  --input-padding: 12px;
  --input-shadow: none;
  --user-input-selected-color: var(--secondary-interactive-color);
  --user-input-selected-contrast-color: var(
    --secondary-interactive-contrast-color
  );

  // Decoration colours
  --decoration-color-1: #f27f20;
  --decoration-color-1--text: #f28d80;
  --decoration-color-2: #b0117d;
  --decoration-color-3: #3b8524;
  --decoration-color-4: #0a93d3;

  @include dgns-typography-preset.set-vars();
}
