@use './../abstracts/breakpoints' as bp;

@mixin base-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

@mixin base-stack {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}

@mixin no-gutters {
  margin-left: 0;
  margin-right: 0;
}

@mixin gutters {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

@mixin negative-gutters() {
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
}

@mixin column-generator($size) {
  .#{$size}--hidden {
    display: none;
  }

  .#{$size}--inline {
    display: inline;
  }

  .#{$size}--block {
    display: block;
  }

  .#{$size}--stack {
    @include base-stack;
  }

  .#{$size}--nowrap {
    flex-wrap: nowrap;
  }

  .#{$size}--wrap {
    flex-wrap: wrap;
  }

  .#{$size}--fit {
    flex-basis: auto;
  }

  .#{$size}--middle {
    align-items: center;
  }

  .#{$size}-row {
    @include base-row;
  }

  .#{$size}-row--negative {
    @include base-row;
    @include negative-gutters;
  }

  /*
   * Add more utils here if need be.
   * E.G.
    .#{$size}-around {
      justify-content: space-around;
    }
   */

  $columns: 12;

  @for $i from 1 through $columns {
    .#{$size}-#{$i} {
      @include gutters;

      flex: 0 0 auto;
      flex-basis: calc(#{percentage($i / $columns)} - calc(var(--gutter) * 2));
      max-width: calc(#{percentage($i / $columns)} - calc(var(--gutter) * 2));
    }

    .#{$size}-#{$i}--none {
      @include no-gutters;
      flex: 0 0 percentage($i / $columns);
      max-width: percentage($i / $columns);
    }

    .#{$size}-#{$i}--negative {
      @include negative-gutters;
      flex: 0 0 auto;
      flex-basis: calc(#{percentage($i / $columns)} + calc(var(--gutter) * 2));
      max-width: calc(#{percentage($i / $columns)} + calc(var(--gutter) * 2));
    }

    .#{$size}-order-#{$i} {
      order: #{$i};
    }
  }

  .#{$size}-order-0 {
    order: 0;
  }
}

.container {
  width: 100%;
  max-width: var(--container-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: calc(var(--gutter) * 2);
  padding-right: calc(var(--gutter) * 2);
  @include bp.breakpoint(sm) {
    padding-left: calc(var(--gutter) * 2);
    padding-right: calc(var(--gutter) * 2);
  }
  &--row {
    flex-direction: row;
  }
}

@include column-generator('xs');

@include bp.breakpoint(sm) {
  @include column-generator('sm');
}

@include bp.breakpoint(md) {
  @include column-generator('md');
}

@include bp.breakpoint(lg) {
  @include column-generator('lg');
}

@include bp.breakpoint(xl) {
  @include column-generator('xl');
}
