@use "./breakpoints" as bp;

@function spacing($size, $adjustment: false) {
  @if ($adjustment) {
    @return calc((var(--spacing-unit) * #{$size}) + #{$adjustment});
  } @else {
    @return calc(var(--spacing-unit) * #{$size});
  }
}

@mixin spacing-based-style($property, $size, $adjustment: false) {
  @if ($size==0) {
    #{$property}: if($adjustment, $adjustment, 0);
  } @else {
    #{$property}: spacing($size, $adjustment);
  }
}

@mixin margin($size: 1, $adjustment: false) {
  @include spacing-based-style('margin', $size, $adjustment: $adjustment);
}

@mixin margin-top($size: 1, $adjustment: false) {
  @include spacing-based-style('margin-top', $size, $adjustment: $adjustment);
}

@mixin margin-bottom($size: 1, $adjustment: false) {
  @include spacing-based-style(
    'margin-bottom',
    $size,
    $adjustment: $adjustment
  );
}

@mixin margin-left($size: 1, $adjustment: false) {
  @include spacing-based-style('margin-left', $size, $adjustment: $adjustment);
}

@mixin margin-right($size: 1, $adjustment: false) {
  @include spacing-based-style('margin-right', $size, $adjustment: $adjustment);
}

@mixin margin-x($size: 1, $adjustment: false) {
  @include spacing-based-style('margin-left', $size, $adjustment: $adjustment);
  @include spacing-based-style('margin-right', $size, $adjustment: $adjustment);
}

@mixin margin-y($size: 1, $adjustment: false) {
  @include spacing-based-style('margin-top', $size, $adjustment: $adjustment);
  @include spacing-based-style(
    'margin-bottom',
    $size,
    $adjustment: $adjustment
  );
}

@mixin padding($size: 1, $adjustment: false) {
  @include spacing-based-style('padding', $size, $adjustment: $adjustment);
}

@mixin padding-bottom($size: 1, $adjustment: false) {
  @include spacing-based-style(
    'padding-bottom',
    $size,
    $adjustment: $adjustment
  );
}

@mixin padding-top($size: 1, $adjustment: false) {
  @include spacing-based-style('padding-top', $size, $adjustment: $adjustment);
}

@mixin padding-left($size: 1, $adjustment: false) {
  @include spacing-based-style('padding-left', $size, $adjustment: $adjustment);
}

@mixin padding-right($size: 1, $adjustment: false) {
  @include spacing-based-style(
    'padding-right',
    $size,
    $adjustment: $adjustment
  );
}

@mixin padding-x($size: 1, $adjustment: false) {
  @include spacing-based-style('padding-left', $size, $adjustment: $adjustment);
  @include spacing-based-style(
    'padding-right',
    $size,
    $adjustment: $adjustment
  );
}

@mixin padding-y($size: 1, $adjustment: false) {
  @include spacing-based-style('padding-top', $size, $adjustment: $adjustment);
  @include spacing-based-style('padding-bottom', $size);
}

@mixin height($size, $adjustment: false) {
  @include spacing-based-style('height', $size, $adjustment: $adjustment);
}

@mixin max-unit-sm() {
  --spacing-unit: var(--spacing-unit-sm);
}

@mixin min-unit-lg() {
  --spacing-unit: var(--spacing-unit-lg);
}

@mixin position-top($size: 1) {
  @include spacing-based-style('top', $size);
}
@mixin position-bottom($size: 1) {
  @include spacing-based-style('bottom', $size);
}
@mixin position-left($size: 1) {
  @include spacing-based-style('left', $size);
}
@mixin position-right($size: 1) {
  @include spacing-based-style('right', $size);
}
