@use '../abstracts/breakpoints' as bp;
@use '../abstracts/spacing' as s;

// this will produce utility classes such as:
// .mb-1 for margin bottom etc.
// based on https://getbootstrap.com/docs/4.0/utilities/spacing/
// [mp][lrbtxy]-[01234]

@mixin _spacing-util-classes($prefix: '') {
  $_sides: (
    't': (
      'top',
    ),
    'b': (
      'bottom',
    ),
    'l': (
      'left',
    ),
    'r': (
      'right',
    ),
    'x': (
      'left',
      'right',
    ),
    'y': (
      'top',
      'bottom',
    ),
    '': (
      'top',
      'bottom',
      'left',
      'right',
    ),
  );
  $_sizes: 0, 0.5, 1, 2, 3, 4;
  $_props: (
    'm': 'margin',
    'p': 'padding',
  );
  @each $prop-initial, $prop in $_props {
    @each $side, $size-props in $_sides {
      @each $size in $_sizes {
        $size-name: if($size==0.5, 'half', $size);
        .#{$prefix}#{$prop-initial}#{$side}-#{$size-name} {
          @each $size-prop in $size-props {
            @include s.spacing-based-style(#{$prop}-#{$size-prop}, $size);
          }
        }
      }
    }
  }
}

//general classes e.g. p-1
@include _spacing-util-classes();

//breakpoint specific classes e.g. sm-p-1
@each $bp in (sm, md, lg, xl) {
  @include bp.breakpoint($bp) {
    @include _spacing-util-classes('#{$bp}-');
  }
}

//by placing on an element, itself and all children will limit spacing unit to small - useful for column layouts
.spacing-max-sm {
  @include s.max-unit-sm();
}
.spacing-min-lg {
  @include s.min-unit-lg();
}

//this is the key bit of the solution that switches between small and large spacing units
body {
  --spacing-unit: var(--spacing-unit-sm);
  @include bp.breakpoint(md) {
    --spacing-unit: var(--spacing-unit-lg);
  }
}
