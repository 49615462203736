$screen-xxs-min: 375px;
$screen-xs-min: 568px;
$screen-sm-min: 667px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1280px;
$screen-xxl-min: 1366px;

$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

/* Usage:

@include breakpoint(sm) {
      margin-right: 0;
}
@include breakpoint(sm-only) {
      margin-right: 0;
}
@include breakpoint(0, $screen-md-max) {
      margin-right: 0;
}

 */

@mixin breakpoint($point, $point2: false) {
  @if $point == xxs-only {
    @media only screen and (max-width: $screen-xxs-min) {
      @content;
    }
  } @else if $point == xs {
    @media only screen and (min-width: $screen-xs-min) {
      @content;
    }
  } @else if $point == xs-only {
    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $point == sm {
    @media only screen and (min-width: $screen-sm-min) {
      @content;
    }
  } @else if $point == sm-only {
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $point == md {
    @media only screen and (min-width: $screen-md-min) {
      @content;
    }
  } @else if $point == md-only {
    @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $point == lg {
    @media only screen and (min-width: $screen-lg-min) {
      @content;
    }
  } @else if $point == lg-only {
    @media only screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      @content;
    }
  } @else if $point == xl {
    @media only screen and (min-width: $screen-xl-min) {
      @content;
    }
  } @else if $point == xl-only {
    @media only screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
      @content;
    }
  } @else if $point2 {
    @media only screen and (min-width: $point) and (max-width: $point2) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $point) {
      @content;
    }
  }
}

@mixin handheld($orientation) {
  @if $orientation == landscape {
    @media only screen and (max-width: $screen-sm-min) and (orientation: landscape) {
      @content;
    }
  }
  @if $orientation == portrait {
    @media only screen and (max-width: $screen-sm-max) and (orientation: portrait) {
      @content;
    }
  }
}
