/**

  There are a lot of button variables here to support old, new and whitelabel styles

  Further alignment on appearance to reduce flexibility and make design system will reduce variables, and total CSS

  For example, the disabled state - recommended everyone uses opacity: 0.5 to show disabled state instead of grey
  this would remove the need for separate disabled vars

 */
@mixin defaults() {
  --btn-disabled-shadow: 0 0 3px var(--tone-300);
  --btn-hover-shadow: none;
  --btn-disabled-opacity: 1;
  --btn-border-radius: var(--border-radius-md);
  //todo: 1.5px!?
  --btn-border-width: 2px;
  --btn-transition: all var(--ease-transition);
  --btn-active-scale: 1;
  --btn-height: 56px;
  --btn-max-width: 280px;
  --btn-max-width-sm: auto;
  // "center" will effectively align text and icon in middle of button
  // "space-between" will align text to left and icon to right (or vice versa if swapped)
  --btn-justify-content: center;
  --btn-text-align: center;
  --btn-icon-spacing: 10px;
  --btn-padding: 13px 20px;

  // Buttons: Primary
  --btn-primary-color: var(--primary-interactive-contrast-color);
  --btn-primary-text-decoration: none;
  --btn-primary-background-color: var(--primary-interactive-color);
  --btn-primary-border-color: var(--primary-interactive-color);
  --btn-primary-hover-color: var(--primary-interactive-color--hover);
  --btn-primary-hover-text-decoration: none;
  --btn-primary-hover-background-color: var(--primary-interactive-color--hover);
  --btn-primary-hover-border-color: var(--primary-interactive-color--hover);
  --btn-primary-active-color: var(--btn-primary-color);
  --btn-primary-active-text-decoration: none;
  --btn-primary-active-background-color: var(--btn-primary-background-color);
  --btn-primary-active-border-color: var(--btn-primary-border-color);
  --btn-primary-focus-border-color: var(--btn-primary-hover-border-color);
  --btn-primary-disabled-color: var(--tone-300);
  --btn-primary-disabled-text-decoration: none;
  --btn-primary-disabled-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-primary-disabled-border-color: var(--tone-300);
  --btn-primary-disabled-hover-color: var(--tone-300);
  --btn-primary-disabled-hover-text-decoration: none;
  --btn-primary-disabled-hover-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-primary-disabled-hover-border-color: var(--tone-300);

  // Buttons: Secondary
  --btn-secondary-color: var(--primary-interactive-color);
  --btn-primary-text-decoration: none;
  --btn-secondary-background-color: transparent;
  --btn-secondary-border-color: var(--primary-interactive-color);
  --btn-secondary-hover-color: var(--primary-interactive-color--hover);
  --btn-secondary-hover-text-decoration: none;
  --btn-secondary-hover-background-color: transparent;
  --btn-secondary-hover-border-color: var(--primary-interactive-color--hover);
  --btn-secondary-active-color: var(--btn-secondary-color);
  --btn-secondary-active-text-decoration: none;
  --btn-secondary-active-background-color: var(
    --btn-secondary-background-color
  );
  --btn-secondary-active-border-color: var(--btn-secondary-border-color);
  --btn-secondary-focus-border-color: var(--btn-secondary-hover-border-color);
  --btn-secondary-disabled-color: var(--tone-300);
  --btn-secondary-disabled-text-decoration: none;
  --btn-secondary-disabled-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-secondary-disabled-border-color: var(--tone-300);
  --btn-secondary-disabled-hover-color: var(--tone-300);
  --btn-secondary-disabled-hover-text-decoration: none;
  --btn-secondary-disabled-hover-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-secondary-disabled-hover-border-color: var(--tone-300);

  // Buttons: Tertiary
  --btn-tertiary-color: var(--tone-700);
  --btn-tertiary-text-decoration: none;
  --btn-tertiary-background-color: var(--primary-interactive-contrast-color);
  --btn-tertiary-border-color: var(--tone-400);
  --btn-tertiary-hover-color: var(--tone-700);
  --btn-tertiary-hover-text-decoration: none;
  --btn-tertiary-hover-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-tertiary-hover-border-color: var(--tone-600);
  --btn-tertiary-active-color: var(--btn-tertiary-color);
  --btn-tertiary-active-text-decoration: none;
  --btn-tertiary-active-background-color: var(--btn-tertiary-background-color);
  --btn-tertiary-active-border-color: var(--btn-tertiary-border-color);
  --btn-tertiary-focus-border-color: var(--btn-tertiary-hover-border-color);
  --btn-tertiary-disabled-color: var(--tone-300);
  --btn-tertiary-disabled-text-decoration: none;
  --btn-tertiary-disabled-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-tertiary-disabled-border-color: var(--tone-300);
  --btn-tertiary-disabled-hover-color: var(--tone-300);
  --btn-tertiary-disabled-hover-text-decoration: none;
  --btn-tertiary-disabled-hover-background-color: var(
    --primary-interactive-contrast-color
  );
  --btn-tertiary-disabled-hover-border-color: var(--tone-300);

  // Buttons: Toggle
  --btn-toggle-color: var(--tone-700);
  --btn-primary-text-decoration: none;
  --btn-toggle-background-color: var(--primary-interactive-contrast-color);
  --btn-toggle-border-color: var(--primary-interactive-color);
  --btn-toggle-hover-color: var(--primary-interactive-contrast-color--hover);
  --btn-toggle-hover-text-decoration: none;
  --btn-toggle-hover-background-color: var(--primary-interactive-color--active);
  --btn-toggle-hover-border-color: var(--primary-interactive-color--active);
  --btn-toggle-active-color: var(--primary-interactive-contrast-color--active);
  --btn-toggle-active-text-decoration: none;
  --btn-toggle-active-background-color: var(
    --primary-interactive-color--active
  );
  --btn-toggle-active-border-color: var(--primary-interactive-color--active);
  --btn-toggle-focus-border-color: var(--btn-toggle-hover-border-color);
  --btn-toggle-disabled-color: var(--tone-300);
  --btn-toggle-disabled-text-decoration: none;
  --btn-toggle-disabled-background-color: var(--primary-contrast);
  --btn-toggle-disabled-border-color: var(--tone-300);
  --btn-toggle-disabled-hover-color: var(--tone-300);
  --btn-toggle-disabled-hover-text-decoration: none;
  --btn-toggle-disabled-hover-background-color: var(--primary-contrast);
  --btn-toggle-disabled-hover-border-color: var(--tone-300);
}
