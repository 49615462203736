@use './../abstracts/breakpoints' as bp;
@use './../abstracts/typography' as t;

/*
* Static: background-color | border-color | color | text-decoration
* Hover (optional): background-color | border-color | color | text-decoration
* Active (optional): background-color | border-color | color | text-decoration
* Focus (optional):  border-color | text-decoration
* Usage
    @include _btn(
      var(--primary-contrast),
      var(--primary-500),
      var(--primary-500),
      var(--primary-contrast),
      var(--primary-700),
      var(--primary-700)
    );
*/

@mixin _btn(
  $color,
  $bg,
  $bc,
  $hover-color: false,
  $hover-bg: false,
  $hover-bc: false,
  $active-color: false,
  $active-bg: false,
  $active-bc: false,
  $focus-bc: false,
  $td: false,
  $hover-td: false,
  $active-td: false,
  $focus-td: false,
  $active-selector: false
) {
  color: $color;
  background-color: $bg;
  border-color: $bc;
  @if $td {
    text-decoration: $td;
  }
  &:hover {
    @if $hover-color {
      color: $hover-color;
    }
    @if $hover-bg {
      background-color: $hover-bg;
    }
    @if $hover-bc {
      border-color: $hover-bc;
    }
    @if $hover-td {
      text-decoration: $hover-td;
      --btn-text-decoration: #{$hover-td};
    }
  }
  #{if($active-selector,$active-selector + ",","")}&:active {
    &:not(.btn--disabled):not(:disabled) {
      outline: none;
      @if $active-color {
        color: $active-color;
      }
      @if $active-bg {
        background-color: $active-bg;
      }
      @if $active-bc {
        border-color: $active-bc;
      }
      @if $active-td {
        text-decoration: $active-td;
        --btn-text-decoration: #{$active-td};
      }
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  //todo: shift to keyboard only focus styles needs polyfil for safari, and means no support on mobile
  &:focus-visible:not(:active) {
    //todo: outline does not have radius - this needs to be border instead (however for whitelabel we need to vary width of border)
    @if $focus-bc {
      //todo: clarify outline vs border
      outline-color: $focus-bc;
    } @else if $hover-bc {
      outline-color: $hover-bc;
    } @else {
      outline-color: $bc;
    }
    @if $focus-td {
      text-decoration: $focus-td;
      --btn-text-decoration: #{$focus-td};
    }
  }
  //by setting extra variables we are able to use within our button component scss
  @if $td {
    --btn-text-decoration: #{$td};
  }
}

@mixin _optional-disabled-selector($selector) {
  #{if($selector,$selector + ",","")}&:disabled {
    @content;
  }
}

@mixin base($typography: true, $dimensions: true) {
  display: flex;
  align-items: center;
  justify-content: var(--btn-justify-content);
  text-align: var(--btn-text-align);
  @if ($typography) {
    @include t.button();
  }
  border-radius: var(--btn-border-radius);
  border-style: solid;
  transition: var(--btn-transition);
  cursor: pointer;
  @if ($dimensions) {
    height: var(--btn-height);
    width: var(--btn-width, 100%);
    min-width: var(--btn-min-width-sm);
    max-width: var(--btn-max-width-sm);
    @include bp.breakpoint(sm) {
      min-width: var(--btn-min-width);
      max-width: var(--btn-max-width);
    }
  }
  padding: var(--btn-padding);
  border-width: var(--btn-border-width);

  &--small {
    height: 34px;
    max-width: 190px;
    padding: 5px 20px;
    @include bp.breakpoint(sm) {
      height: 44px;
    }
  }
  &--disabled,
  &:disabled {
    opacity: var(--btn-disabled-opacity);
    box-shadow: var(--btn-disabled-shadow);
  }
  &:hover:not(.btn--disabled):not(:disabled) {
    box-shadow: var(--btn-hover-shadow);
  }
  //spacing between icon and text:
  span.label + .icon {
    margin-left: var(--btn-icon-spacing);
  }
  .icon + span.label {
    margin-left: var(--btn-icon-spacing);
  }
  &:active {
    transform: scale(var(--btn-active-scale));
  }
}

@mixin primary($disabled-selector: false) {
  @include _btn(
    $color: var(--btn-primary-color),
    $bg: var(--btn-primary-background-color),
    $bc: var(--btn-primary-border-color),
    $hover-color: var(--btn-primary-hover-color),
    $hover-bg: var(--btn-primary-hover-background-color),
    $hover-bc: var(--btn-primary-hover-border-color),
    $active-color: var(--btn-primary-active-color),
    $active-bg: var(--btn-primary-active-background-color),
    $active-bc: var(--btn-primary-active-border-color),
    $focus-bc: var(--btn-primary-focus-border-color),
    $td: var(--btn-primary-text-decoration),
    $hover-td: var(--btn-primary-hover-text-decoration),
    $focus-td: var(--btn-primary-focus-text-decoration),
    $active-td: var(--btn-primary-active-text-decoration)
  );
  @include _optional-disabled-selector($disabled-selector) {
    @include _btn(
      $color: var(--btn-primary-disabled-color),
      $bg: var(--btn-primary-disabled-background-color),
      $bc: var(--btn-primary-disabled-border-color),
      $hover-color: var(--btn-primary-disabled-hover-color),
      $hover-bg: var(--btn-primary-disabled-hover-background-color),
      $hover-bc: var(--btn-primary-disabled-hover-border-color),
      $td: var(--btn-primary-disabled-text-decoration),
      $hover-td: var(--btn-primary-disabled-hover-border-color)
    );
  }
}
@mixin primary-inverted($disabled-selector: false) {
  @include _btn(
    $color: var(--btn-primary-inverted-color),
    $bg: var(--btn-primary-inverted-background-color),
    $bc: var(--btn-primary-inverted-border-color),
    $hover-color: var(--btn-primary-inverted-hover-color),
    $hover-bg: var(--btn-primary-inverted-hover-background-color),
    $hover-bc: var(--btn-primary-inverted-hover-border-color),
    $active-color: var(--btn-primary-inverted-active-color),
    $active-bg: var(--btn-primary-inverted-active-background-color),
    $active-bc: var(--btn-primary-inverted-active-border-color),
    $focus-bc: var(--btn-primary-inverted-focus-border-color),
    $td: var(--btn-primary-inverted-text-decoration),
    $hover-td: var(--btn-primary-inverted-hover-text-decoration),
    $focus-td: var(--btn-primary-inverted-focus-text-decoration),
    $active-td: var(--btn-primary-inverted-active-text-decoration)
  );
  @include _optional-disabled-selector($disabled-selector) {
    @include _btn(
      $color: var(--btn-primary-inverted-disabled-color),
      $bg: var(--btn-primary-inverted-disabled-background-color),
      $bc: var(--btn-primary-inverted-disabled-border-color),
      $hover-color: var(--btn-primary-inverted-disabled-hover-color),
      $hover-bg: var(--btn-primary-inverted-disabled-hover-background-color),
      $hover-bc: var(--btn-primary-inverted-disabled-hover-border-color),
      $td: var(--btn-primary-inverted-disabled-text-decoration),
      $hover-td: var(--btn-primary-inverted-disabled-hover-border-color)
    );
  }
}
@mixin secondary($disabled-selector: false) {
  @include _btn(
    $color: var(--btn-secondary-color),
    $bg: var(--btn-secondary-background-color),
    $bc: var(--btn-secondary-border-color),
    $hover-color: var(--btn-secondary-hover-color),
    $hover-bg: var(--btn-secondary-hover-background-color),
    $hover-bc: var(--btn-secondary-hover-border-color),
    $active-color: var(--btn-secondary-active-color),
    $active-bg: var(--btn-secondary-active-background-color),
    $active-bc: var(--btn-secondary-active-border-color),
    $focus-bc: var(--btn-secondary-focus-border-color),
    $td: var(--btn-secondary-text-decoration),
    $hover-td: var(--btn-secondary-hover-text-decoration),
    $focus-td: var(--btn-secondary-focus-text-decoration),
    $active-td: var(--btn-secondary-active-text-decoration)
  );
  @include _optional-disabled-selector($disabled-selector) {
    @include _btn(
      $color: var(--btn-secondary-disabled-color),
      $bg: var(--btn-secondary-disabled-background-color),
      $bc: var(--btn-secondary-disabled-border-color),
      $hover-color: var(--btn-secondary-disabled-hover-color),
      $hover-bg: var(--btn-secondary-disabled-hover-background-color),
      $hover-bc: var(--btn-secondary-disabled-hover-border-color),
      $td: var(--btn-secondary-disabled-text-decoration),
      $hover-td: var(--btn-secondary-disabled-hover-border-color)
    );
  }
}
@mixin tertiary($disabled-selector: false) {
  @include _btn(
    $color: var(--btn-tertiary-color),
    $bg: var(--btn-tertiary-background-color),
    $bc: var(--btn-tertiary-border-color),
    $hover-color: var(--btn-tertiary-hover-color),
    $hover-bg: var(--btn-tertiary-hover-background-color),
    $hover-bc: var(--btn-tertiary-hover-border-color),
    $active-color: var(--btn-tertiary-active-color),
    $active-bg: var(--btn-tertiary-active-background-color),
    $active-bc: var(--btn-tertiary-active-border-color),
    $focus-bc: var(--btn-tertiary-focus-border-color),
    $td: var(--btn-tertiary-text-decoration),
    $hover-td: var(--btn-tertiary-hover-text-decoration),
    $focus-td: var(--btn-tertiary-focus-text-decoration),
    $active-td: var(--btn-tertiary-active-text-decoration)
  );

  @include _optional-disabled-selector($disabled-selector) {
    @include _btn(
      $color: var(--btn-tertiary-disabled-color),
      $bg: var(--btn-tertiary-disabled-background-color),
      $bc: var(--btn-tertiary-disabled-border-color),
      $hover-color: var(--btn-tertiary-disabled-hover-color),
      $hover-bg: var(--btn-tertiary-disabled-hover-background-color),
      $hover-bc: var(--btn-tertiary-disabled-hover-border-color),
      $td: var(--btn-tertiary-disabled-text-decoration),
      $hover-td: var(--btn-tertiary-disabled-hover-border-color)
    );
  }
}

@mixin toggle($disabled-selector: false, $active-selector: false) {
  @include _btn(
    $color: var(--btn-toggle-color),
    $bg: var(--btn-toggle-background-color),
    $bc: var(--btn-toggle-border-color),
    $hover-color: var(--btn-toggle-hover-color),
    $hover-bg: var(--btn-toggle-hover-background-color),
    $hover-bc: var(--btn-toggle-hover-border-color),
    $active-color: var(--btn-toggle-active-color),
    $active-bg: var(--btn-toggle-active-background-color),
    $active-bc: var(--btn-toggle-active-border-color),
    $focus-bc: var(--btn-toggle-focus-border-color),
    $td: var(--btn-toggle-text-decoration),
    $hover-td: var(--btn-toggle-hover-text-decoration),
    $focus-td: var(--btn-toggle-focus-text-decoration),
    $active-td: var(--btn-toggle-active-text-decoration),
    $active-selector: $active-selector
  );

  @include _optional-disabled-selector($disabled-selector) {
    @include _btn(
      $color: var(--btn-toggle-disabled-color),
      $bg: var(--btn-toggle-disabled-background-color),
      $bc: var(--btn-toggle-disabled-border-color),
      $hover-color: var(--btn-toggle-disabled-hover-color),
      $hover-bg: var(--btn-toggle-disabled-hover-background-color),
      $hover-bc: var(--btn-toggle-disabled-hover-border-color),
      $td: var(--btn-toggle-disabled-text-decoration),
      $hover-td: var(--btn-toggle-disabled-hover-border-color)
    );
  }
  #{if($active-selector,$active-selector + ",","")}&:active{
    --btn-hover-shadow: none;
  }
}
