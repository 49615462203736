@mixin set-vars() {
  --typog-default-heading-family: 'Museo Sans', 'Source Sans Pro', Arial,
  sans-serif;
  --typog-default-body-family: 'Source Sans Pro', Arial, sans-serif;

  --typog-d1-weight: 300;
  --typog-d1-size: 50px;
  --typog-d1-line-height: 65px;
  --typog-d1-letter-spacing: 0.6px;
  --typog-sm-d1-size: 42px;
  --typog-sm-d1-line-height: 55px;
  --typog-sm-d1-letter-spacing: 0.4px;

  --typog-d2-weight: 700;
  --typog-d2-size: 32px;
  --typog-d2-line-height: 42px;
  --typog-d2-letter-spacing: 0.6px;
  --typog-sm-d2-size: 28px;
  --typog-sm-d2-line-height: 37px;
  --typog-sm-d2-letter-spacing: 0.4px;

  --typog-h1-weight: 300;
  --typog-h1-size: 42px;
  --typog-h1-line-height: 54px;
  --typog-h1-letter-spacing: 0.6px;
  --typog-sm-h1-size: 34px;
  --typog-sm-h1-line-height: 44px;
  --typog-sm-h1-letter-spacing: 0.4px;

  --typog-h2-weight: 300;
  --typog-h2-size: 32px;
  --typog-h2-line-height: 42px;
  --typog-h2-letter-spacing: 0.6px;
  --typog-sm-h2-size: 28px;
  --typog-sm-h2-line-height: 37px;
  --typog-sm-h2-letter-spacing: 0.4px;

  --typog-h3-weight: bold;
  --typog-h3-size: 26px;
  --typog-h3-line-height: 33px;
  --typog-h3-letter-spacing: 0.4px;
  --typog-sm-h3-size: 24px;
  --typog-sm-h3-line-height: 32px;

  --typog-h4-weight: bold;
  --typog-h4-size: 22px;
  --typog-h4-line-height: 29px;
  --typog-h4-letter-spacing: 0.4px;
  --typog-sm-h4-size: 20px;
  --typog-sm-h4-line-height: 26px;

  --typog-h5-weight: bold;
  --typog-h5-size: 20px;
  --typog-h5-line-height: 26px;
  --typog-h5-letter-spacing: 0.4px;
  --typog-sm-h5-size: 17px;
  --typog-sm-h5-line-height: 23px;

  --typog-h6-weight: bold;
  --typog-h6-size: 16px;
  --typog-h6-line-height: 150%;
  --typog-h6-letter-spacing: 0.02em;
  --typog-h6-text-transform: uppercase;
  --typog-sm-h6-size: 14px;
  --typog-sm-h6-line-height: 115%;
  --typog-sm-h6-letter-spacing: -0.02em;
  --typog-sm-h6-text-transform: uppercase;

  --typog-button-family: var(--typog-default-heading-family);
  --typog-button-weight: bold;
  --typog-button-size: 19px;
  --typog-button-line-height: 24px;
  --typog-button-letter-spacing: 0.4px;

  --typog-body-large-weight: normal;
  --typog-body-large-size: 18px;
  --typog-body-large-line-height: 28px;
  --typog-body-large-letter-spacing: 0.2px;

  --typog-body-large-bold-weight: 600;
  --typog-body-large-bold-size: 18px;
  --typog-body-large-bold-line-height: 28px;
  --typog-body-large-bold-letter-spacing: 0.2px;

  --typog-body-large-link-weight: 600;
  --typog-body-large-link-size: 18px;
  --typog-body-large-link-line-height: 30px;
  --typog-body-large-link-letter-spacing: 0.4px;
  --typog-body-large-link-text-decoration: underline;
  --typog-body-large-link-hover-text-decoration: none;

  --typog-body-regular-weight: normal;
  --typog-body-regular-size: 16px;
  --typog-body-regular-line-height: 24px;
  --typog-body-regular-letter-spacing: 0.2px;

  --typog-body-bold-weight: 600;
  --typog-body-bold-size: 16px;
  --typog-body-bold-line-height: 24px;
  --typog-body-bold-letter-spacing: 0.2px;

  --typog-body-link-weight: 600;
  --typog-body-link-size: 16px;
  --typog-body-link-line-height: 24px;
  --typog-body-link-letter-spacing: 0.4px;
  --typog-body-link-text-decoration: underline;
  --typog-body-link-hover-text-decoration: none;

  --typog-caption-bold-weight: 600;
  --typog-caption-bold-size: 14px;
  --typog-caption-bold-line-height: 24px;
  --typog-caption-bold-letter-spacing: 0.4px;

  --typog-caption-regular-weight: normal;
  --typog-caption-regular-size: 14px;
  --typog-caption-regular-line-height: 24px;
  --typog-caption-regular-letter-spacing: 0.4px;

  --typog-caption-link-weight: 600;
  --typog-caption-link-size: 16px;
  --typog-caption-link-line-height: 21px;
  --typog-caption-link-letter-spacing: 0.4px;
  --typog-caption-link-text-decoration: none;
  --typog-caption-link-hover-text-decoration: underline;

  --typog-menu-link-weight: normal;
  --typog-menu-link-size: 16px;
  --typog-menu-link-line-height: 24px;
  --typog-menu-link-text-decoration: none;
  --typog-menu-link-hover-text-decoration: underline;
  --typog-menu-link-letter-spacing: 0.05em;

  --typog-header-link-weight: normal;
  --typog-header-link-size: 20px;
  --typog-header-link-line-height: 24px;
  --typog-header-link-text-decoration: none;
  --typog-header-link-hover-text-decoration: none;
  --typog-header-link-letter-spacing: calc(0.1em / 3);
  --typog-header-link-family: var(--typog-default-body-family);
  --typog-sm-header-link-size: 18px;
  --typog-sm-header-link-line-height: 24px;
}
