@mixin font-definition($path){
  @font-face {
    font-family: 'icomoon';
    src: url('#{$path}icons.ttf?c3zd62') format('truetype'),
    url('#{$path}icons.woff?c3zd62') format('woff'),
    url('#{$path}icons.svg?c3zd62#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
}
