@mixin combined($path) {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('#{$path}/inter/inter-v3-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('#{$path}/inter/inter-v3-latin-regular.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('#{$path}/inter/inter-v3-latin-regular.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('#{$path}/inter/inter-v3-latin-regular.woff') format('woff'),
      /* Modern Browsers */ url('#{$path}/inter/inter-v3-latin-regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('#{$path}/inter/inter-v3-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('#{$path}/inter/inter-v3-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('#{$path}/inter/inter-v3-latin-700.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('#{$path}/inter/inter-v3-latin-700.woff2')
        format('woff2'),
      /* Super Modern Browsers */ url('#{$path}/inter/inter-v3-latin-700.woff')
        format('woff'),
      /* Modern Browsers */ url('#{$path}/inter/inter-v3-latin-700.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('#{$path}/inter/inter-v3-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
  }
}

@mixin separate($path) {
  @error ("Inter does not have separate implemented");
}
