.is-hidden {
  display: none;
}

.is-visible {
  display: block;
}

.is-invisible {
  visibility: hidden;
}

.margin--none {
  margin: 0 !important;
}

.padding--none {
  padding: 0 !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
}

.align {
  &--start {
    align-items: flex-start;
    align-content: flex-start;
  }
  &--center {
    align-items: center;
    align-content: center;
  }
  &--end {
    align-items: flex-end;
    align-content: flex-end;
  }
  &--space-between {
    align-items: flex-start;
    align-content: space-between;
  }
  &--space-around {
    align-items: flex-start;
    align-content: space-around;
  }
  &--stretch {
    align-items: stretch;
    align-content: stretch;
  }
}

.justify {
  &--start {
    justify-content: flex-start;
  }
  &--center {
    justify-content: center;
  }
  &--end {
    justify-content: flex-end;
  }
  &--space-between {
    justify-content: space-between;
  }
  &--space-around {
    justify-content: space-around;
  }
}

.text {
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
  &--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &--focusable:active,
  &--focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
