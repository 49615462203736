@use "./typography" as t;
@use "./spacing" as s;
/**
 * Box mixin is used to style an element as a "box like" input field - i.e. input, select, textarea etc.
 * Anything with a border that wants to be consistent
 * Adds Borders, Padding, Shadow, Constrains width - all dependent on theme variables
 */
@mixin box {
  height: auto;
  width: 100%;
  max-width: var(--max-input-width, auto);
  background: var(--input-background-color);
  border-radius: var(--input-border-radius);
  border: solid var(--input-border-width) var(--input-border-color);
  padding: var(--input-padding);
  box-shadow: var(--input-shadow);
  transition: all var(--ease-transition);
}
/**
 * Use with box mixin to adjust to show focused style
 */
@mixin box--focus {
  outline-width: 2px;
  outline-color: var(--input-border-color--focus);
}

/**
 * Use with box mixin to adjust to show valid style
 */
@mixin box--valid {
  border: var(--input-border-width--valid) solid
    var(--input-border-color--valid);
}

/**
 * Use with box mixin to adjust to show invalid style
 */
@mixin box--invalid {
  border: var(--input-border-width--invalid) solid var(--error);
}

/**
 * Use with box mixin to adjust to support a trigger being shown
 * It adds additional padding to make room for an absolutely positioned trigger.
 * Should be used on an element within an element using box-wrapper,
 * and the trigger mixin should be used on the trigger element itself
 */
@mixin box--with-trigger {
  padding: var(--input-padding);
  // input-padding once from edge of field, and once to space between text
  padding-right: calc(
    var(--input-padding) + var(--input-trigger-size) + var(--input-padding)
  );
}

/**
 * Use with box mixin to automatically add valid and invalid states based on angular forms automatic classes:
 *  - ng-touched
 *  - ng-valid
 *  - ng-invalid
 *
 * Should be added to the form control element
 * (usually :host{} on your component when implementing control value accessor)
 * $selector argument allows you to pass in the child selector for the actual component you want styles applied to
 * (i.e. the one you have added box mixin to)
 */
@mixin box--ng-based-validation($selector: '.form-control') {
  &.ng-touched {
    &.ng-invalid {
      #{$selector} {
        @include box--invalid();
      }
    }

    &.ng-valid {
      #{$selector} {
        @include box--valid();
      }
    }
  }
}

/**
 * Used to wrap around fields to allow triggers and validation icons to be positioned
 */
@mixin box-wrapper() {
  position: relative;
  width: 100%;
  max-width: var(--max-input-width, auto);
}

/**
 * Use on input fields and selects to combine box mixin, with focus state and placeholder pseudo element styles
 */
@mixin textfield {
  @include box();
  @include text();
  &:focus {
    @include box--focus();
  }
  /* placeholders */
  &::placeholder {
    opacity: 1; /* Firefox */
    @include text--placeholder();
  }
  &::-webkit-input-placeholder {
    @include text--placeholder();
  }
  &:-ms-input-placeholder {
    @include text--placeholder();
  }
}

/**
 * Used to style icon to be used as a trigger (e.g. search, clear or down arrow)
 * Place inside an element with box-wrapper()
 * Combine with an element using box--with-trigger() to position correctly
 */
@mixin trigger {
  cursor: pointer;
  color: var(--input-trigger-color);
  font-size: var(--input-trigger-size);
  position: absolute;
  top: 50%;
  margin: calc(-0.5 * var(--input-trigger-size)) 0;
  right: var(--input-padding);
  transition: transform var(--ease-transition);
  z-index: 1;
}

/**
 * Standard input text (i.e. input field or select)
 */
@mixin text {
  color: var(--text-color);
  @include t.body-bold();
}

/**
 * Placeholder text used on input fields (can also be used on selects with custom implementation to emulate placeholder)
 */
@mixin text--placeholder {
  color: var(--input-placeholder-color);
  @include t.body-regular();
}
/**
 * Field label
 */
@mixin text--label {
  color: var(--text-color--supporting);
  @include t.body-large-bold();
}

/**
 * Hint text to appear next to the field
 */
@mixin text--hint {
  color: var(--text-color--unimportant);
  @include t.caption-regular();
}

/**
 * Error message text
 */
@mixin text--error {
  color: var(--error);
  @include t.caption-regular();
}

@mixin label__text {
  @warn ("label__text is deprecated use text--label instead");
  @include text--label();
}

@mixin hint__text {
  @warn ("hint__text is deprecated use text--hint instead");
  @include text--hint();
}

@mixin error__text {
  @warn ("error__text is deprecated use text--error instead");
  @include text--error();
}

/**
 * Base mixin for validation icon (tick/cross top/right of fields)
 */
@mixin validation-icon {
  width: var(--input-error-icon-circle-size);
  height: var(--input-error-icon-circle-size);
  border-radius: 50%;
  top: calc(-0.5 * var(--input-error-icon-circle-size));
  right: calc(-0.4 * var(--input-error-icon-circle-size));
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--ease-transition);
  transform: scale(0);
  z-index: 2;
}

/**
 * Use with validation-icon mixin to show valid state
 */
@mixin validation-icon--valid {
  transform: scale(1);
  background: var(--success);
  color: var(--success--contrast);
  &::before {
    font-size: var(--input-error-icon-size) !important;
  }
}

/**
 * Use with validation-icon mixin to show invalid state
 */
@mixin validation-icon--invalid {
  transform: scale(1);
  background: var(--error);
  color: var(--error--contrast);
  &::before {
    //todo: update icons so they are consistently sized
    font-size: calc(0.8 * var(--input-error-icon-size));
    font-weight: bold;
  }
}

/**
 * Card of tooltip in expanded state
 */
@mixin tooltip__card {
  @include s.padding(1);
  @include t.body-regular();
  padding: 30px;
  background-color: var(--form-card-bg);
  border-top: var(--card-highlight-width) solid var(--primary-interactive-color);
}

/**
 * Icon of tooltip to be shown next to field label
 */
@mixin tooltip__icon {
  //todo: use vars
  font-size: 16px;
  @include s.padding-x(0);
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    border-radius: 16px;
  }
}

/**
 * Radio field, no specific markup needed - use on standard <input type="radio"/>
 */
@mixin radiofield {
  --border: var(--tone-600);
  --dot-transition: 0.3s ease;
  --dot-opacity: 0.2s ease;

  -webkit-appearance: none;
  -moz-appearance: none;
  height: 22px;
  width: 22px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: var(--border-width) solid var(--border);
  background: var(--user-input-selected-contrast-color);
  border-radius: 50%;

  //inner "dot"
  &:after {
    content: '';
    display: flex;
    transition: transform var(--dot-transition),
      opacity var(--dot-opacity, 0.2s);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--user-input-selected-color);
    //starts of hidden for unchecked state
    opacity: 0;
    transform: scale(0);
  }
  &:checked {
    //inner "dot" becomes visible
    &:after {
      --dot-opacity: 0.3s ease;
      --dot-transition: 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2);
      opacity: 1;
      transform: scale(1);
    }
    //outer ring changes color
    border-color: var(--user-input-selected-color);
  }
  &:disabled {
    //inner "dot" is greyed out
    &:after {
      background-color: var(--tone-600);
    }
    //border is same as deselected state
    border-color: var(--border);
    //whole radio becomes slightly transparent
    opacity: 0.9;

    &,
    & + label {
      cursor: not-allowed;
    }
  }
  &:focus {
    border-color: var(--user-input-selected-color);
    //box-shadow: 0px 0px 0px 2px var(--input-border-color--focus);
  }
}
/**
 * This is a reusable select style that relies on a particular dom tree:
 *   <div class="select-wrapper">
 *     <select>...</select>
 *     <i class="icon-down-arrow"></i>
 *   </div>
 */
@mixin select--with-custom-trigger() {
  .select-wrapper {
    @include box-wrapper();
    .icon-arrow-down {
      @include trigger();
      //ensure click events reach the select field
      pointer-events: none;
    }
    select {
      @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        @include textfield();
        @include box--with-trigger();
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &.select--empty {
          color: var(--input-placeholder-color);
          @include t.body-regular();
        }
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}
