@use "../../abstracts/typography" as t;

body {
  color: var(--tone-700);
  -webkit-font-smoothing: antialiased;
  span {
    font-size: inherit;
  }

  //note: no `table td` styles, should be defined on use, or inherit from parent

  .link {
    color: var(--primary-interactive-color);
    cursor: pointer;
  }

  //todo: make css variables
  a,
  .link {
    word-break: break-word;
    &,
    &:focus,
    &:active {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: none;
    }
  }

  a.link--standalone {
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .font {
    &--standard {
      @include t.body-regular();
    }
    &--bold {
      @include t.body-bold();
    }
    &--sm {
      @include t.caption-regular();
    }
    //note: no &--xs style - it is not in styleguide, or used in either sales or pr
  }

  strong {
    @extend .font--bold;
  }
  small {
    @extend .font--sm;
  }
  em {
    font-style: italic;
  }

  h1 {
    @extend .typog-h1;
  }

  h2 {
    @extend .typog-h2;
  }

  h3 {
    @extend .typog-h3;
  }

  h4 {
    @extend .typog-h4;
  }

  h5 {
    @extend .typog-h5;
  }

  h6 {
    @extend .typog-h6;
  }

  &,
  p,
  li {
    @extend .typog-body;

    strong {
      @extend .typog-body-bold;
    }

    a {
      @extend .typog-body-link;
    }
  }
}
